.pee-chee-font {
  font-family: "Pacifico", cursive;
  color: #884121;
}
.barlow-font {
  color: #884121;
  font-family: "Barlow Condensed", sans-serif;
}

.modal-content {
  background-color: #f4a932;
  // background-color: #f4a932;
}

label,
small {
  color: #884121;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 20px;
}

.custom-input {
  font-family: "Barlow Condensed", sans-serif;
  background-color: #884121 !important;
  border-color: #884121 !important;
  color: #f4a932 !important;
}
.form-control:focus {
  outline: none;
  box-shadow: none;
}

/*Change background in autofill textbox*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #884121 inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #f4a932 !important;
}

/* Adjusts the styles for Amplify greetings bar */
div[class^="Nav__navBar"] {
  background-color: transparent;
  border-color: transparent;
}

div[class^="Nav__navItem"] {
  font-family: "Pacifico", cursive;
  font-size: 18px !important;
}

button[class^="Button__button"] {
  background-color: transparent !important;
  border-color: #884121 !important;
  border-width: 2px !important;
  color: #884121 !important;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

a {
  color: #884121;
}

a:hover {
  color: #884121;
}

body {
  color: #884121;
  background-color: #f4a932;
}

.barlow-btn {
  background-color: transparent !important;
  border-color: #884121 !important;
  border-width: 2px !important;
  color: #884121 !important;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.barlow-button:hover {
  color: #f4a932 !important;
  background-color: #884121 !important;
  /* border-color: #884121 !important; */
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 50px !important;
  line-height: 1;

  opacity: 0.75;
  color: #884121 !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 576px) {
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Barlow Condensed", sans-serif;
  color: #ce830b !important;
  opacity: 1; /* Firefox */
  font-size: 18px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Barlow Condensed", sans-serif;
  font-size: 18px;
  color: #f4a932;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Barlow Condensed", sans-serif;
  font-size: 18px;
  color: #f4a932;
}
